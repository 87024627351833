@import '../theme-bootstrap';

.basic-tout {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  word-wrap: break-word;
  &__image {
    margin: 0 auto;
    &.rounded-image {
      img {
        width: 50%;
        margin: 0 auto;
        @include breakpoint($portrait-up) {
          width: 100%;
          padding: 20px 0 0 0;
        }
      }
    }
    .mantle-media-asset {
      img {
        display: block;
      }
    }
  }
  &__text {
    text-align: center;
    padding: 18px 15px 0px 15px;
  }
  &.basic-tout--image-separated {
    .basic-tout__image {
      padding: 20px;
    }
  }

  // Different image alignments
  &--top {
    &.basic-tout--image-separated {
      .basic-tout__image {
        border-bottom: 1px solid $color-light-gray;
      }
    }
  }
  &--bottom {
    flex-direction: column-reverse;
    &.basic-tout--image-separated {
      .basic-tout__image {
        border-top: 1px solid $color-light-gray;
      }
    }
  }
  &--left {
    flex-direction: row;
    .basic-tout__image {
      max-width: 50%;
      margin: 0 10px;
    }
    &.basic-tout--image-separated {
      .basic-tout__image {
        border-right: 1px solid $color-light-gray;
      }
    }
  }
  &--right {
    flex-direction: row-reverse;
    .basic-tout__image {
      max-width: 50%;
      margin: 0 10px;
    }
    &.basic-tout--image-separated {
      .basic-tout__image {
        border-right: 1px solid $color-light-gray;
      }
    }
  }
  &.basic-tout--overlay {
    .basic-tout__image {
      cursor: pointer;
      position: relative;
    }
  }
  &__hover {
    @include transition(opacity 0.2s ease-in-out);
    background-color: $color-white--transparent;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    &-content {
      padding: 0 20px;
      position: absolute;
      text-align: center;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      @include breakpoint($portrait-up) {
        padding: 0 30px;
      }
      @include breakpoint($landscape-up) {
        padding: 0 20px;
      }
      p {
        &:last-child {
          margin: 0;
        }
      }
    }
    .basic-tout__image:hover & {
      opacity: 1;
    }
  }
  &__image-trigger {
    height: 100%;
    width: 100%;
  }
  &__hover-cta {
    margin-top: 20px;
  }
  &__read-reviews-link {
    display: none;
  }
  &__write-a-review {
    text-align: center;
  }
  &__reviews {
    padding-bottom: 25px;
  }
  &__cta {
    text-align: center;
  }
}

.aveda_services {
  #spp-reviews {
    .bv-content-summary-body-text {
      overflow-wrap: break-word;
    }
    .bv-write-review-container {
      display: none !important;
    }
    &.spp-data {
      max-width: 1200px;
    }
  }
}
